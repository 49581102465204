import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../Auth/AuthProvider"; // Update this import based on where AuthProvider is located

const backendUrl = "https://omelive.online";

const DepositPage = () => {
  const { isLoggedIn, authUser, loading } = useContext(AuthContext);
  const [depositAmount, setDepositAmount] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userLanguage, setUserLanguage] = useState("en");

  useEffect(() => {
    if (!loading && isLoggedIn && authUser) {
      setUserLanguage(authUser.language === "Türkçe" ? "tr" : "en");
    }
  }, [loading, isLoggedIn, authUser]);

  const t = {
    en: {
      depositTitle: "Deposit Funds",
      amountPlaceholder: "Enter amount",
      depositButton: "Deposit",
      depositSuccess: "Redirecting to payment...",
      depositFailed: "Failed to initiate payment. Please try again.",
      depositMinimum: "Minimum deposit amount is $1.",
      depositInfo:
        "Enter the amount you wish to deposit. You'll be redirected to the secure payment page to complete your transaction.",
    },
    tr: {
      depositTitle: "Para Yatır",
      amountPlaceholder: "Tutarı girin",
      depositButton: "Yatır",
      depositSuccess: "Ödeme sayfasına yönlendiriliyorsunuz...",
      depositFailed: "Ödeme başlatılamadı. Lütfen tekrar deneyin.",
      depositMinimum: "Minimum para yatırma tutarı 1$'dır.",
      depositInfo:
        "Yatırmak istediğiniz tutarı girin. İşleminizi tamamlamak için güvenli ödeme sayfasına yönlendirileceksiniz.",
    },
  }[userLanguage];

  const handleDeposit = async () => {
    const amount = parseFloat(depositAmount);
    if (isNaN(amount) || amount < 1) {
      toast.error(t.depositMinimum);
      return;
    }

    setIsProcessing(true);

    const paymentData = {
      product: "Deposit",
      referenceCode: `order-${Date.now()}`, // Unique reference code
      referenceUserId: authUser._id, // Use `authUser._id` if `_id` is the MongoDB field
      amount: depositAmount,
      successUrl: "https://omelive.online/payment-success", // Success URL
      failureUrl: "https://omelive.online/payment-failure", // Failure URL
    };

    try {
      const response = await fetch(`${backendUrl}/api/payments/initialize`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentData),
      });

      const { paymentPageUrl } = await response.json();

      window.location.href = paymentPageUrl;
    } catch (error) {
      console.error("Error initializing payment:", error);
      toast.error(t.depositFailed);
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    window.location.href = "/signin";
    return null;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-blue-600 text-white py-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold">Omolive.online</h1>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex justify-center items-center bg-gray-100 py-8">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold mb-4">{t.depositTitle}</h2>
          <p className="text-gray-700 mb-4">{t.depositInfo}</p>
          <input
            type="number"
            placeholder={t.amountPlaceholder}
            value={depositAmount}
            onChange={(e) => setDepositAmount(e.target.value)}
            className="w-full p-2 mb-4 border rounded"
          />
          <button
            onClick={handleDeposit}
            disabled={isProcessing}
            className={`bg-blue-500 text-white py-2 px-4 rounded w-full ${
              isProcessing ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isProcessing ? "Processing..." : t.depositButton}
          </button>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-blue-600 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Omolive.online. All rights reserved.</p>
          <p>
            For support, contact us at{" "}
            <a href="mailto:support@omelive.online" className="underline">
              support@omelive.online
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default DepositPage;
