import React from 'react';

const Contract = () => {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-800">
      <header className="flex justify-center items-center py-4 bg-blue-600 text-white text-2xl">
        omelive.online
      </header>
      <div className="flex-grow p-6 bg-gray-100">
        <div className="max-w-4xl mx-auto bg-white rounded shadow-lg p-6">
          <h1 className="text-2xl font-bold text-center mb-4">KVKK Information Text</h1>
          <p className="mb-4">
            Personal data of people who are members of the Omelive platform or use our services, within the framework of the Personal Data Protection Law No. 6698 (“KVKK”) is being processed. In this context, your personal data may be processed for the following purposes and legal reasons:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li><strong>Use of the Platform:</strong> To carry out the transactions necessary for you to benefit from our platform.</li>
            <li><strong>Communication:</strong> To communicate with you and respond to your requests.</li>
            <li><strong>Security:</strong> To ensure the security of our platform and prevent possible risks.</li>
            <li><strong>Marketing:</strong> To offer you special products and services and to carry out our marketing activities.</li>
          </ul>
          <p>
            Among your processed personal data; your name, surname, username, e-mail address, IP address, there is data regarding your location information and activities on the platform. Your personal data will be stored for legal periods, limited to the purposes stated above.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2">Delivery Terms</h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">Service Delivery</h3>
          <p>
            Our platform offers instant messaging and video chat services. These
            services are delivered digitally and do not require physical delivery.
            Users can start using our services immediately after completing their
            registration process.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Access and Use</h3>
          <p>
            Users gain instant access to the platform once their membership is
            activated. For any access issues during the platform’s technical
            support hours, please contact our support team.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Distance Sales Contract</h2>
          <p>
            This contract has been concluded electronically between the parties whose details are given below, in accordance with the provisions of the Consumer Protection Law No. 6502 and the Distance Contracts Regulation.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Parties</h3>
          <p><strong>Sales Person:</strong></p>
          <p>
            Trade Name: Omelive<br />
            Address: Turkey/Konya<br />
            Tax Office: Çumra Tax Office<br />
            Email: info@omelive.online
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Subject of the Contract</h3>
          <p>
            This agreement is between the parties regarding the sale and performance of the service whose qualifications and sales price are specified below, which the Buyer ordered electronically from the Seller's website www.omelive.online. It determines their rights and obligations.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Qualifications and Price of the Service</h3>
          <p>
            The Buyer accepts the features and fee of the service purchased within the scope of this agreement as stated below.
          </p>
          <p><strong>Type of Service:</strong> Video Chat Platform Usage Service</p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Right of Withdrawal</h3>
          <p>
            In accordance with Article 15 of Law No. 6502, the right of withdrawal cannot be exercised for services performed instantly. The Buyer accepts that he cannot exercise his right of withdrawal once the performance of the service begins.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">General Provisions</h3>
          <p>
            By approving this contract electronically, the Buyer acknowledges that he/she has read the preliminary information form and is informed about the qualifications of the service subject to the contract, sales price, payment method, performance conditions, and declares that he has given the necessary confirmation. The seller is obliged to fix the problem as soon as possible in case of technical problems while performing the service.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Dispute Resolution</h3>
          <p>
            Consumer Arbitration Committees and Consumer Courts in the Seller's place of residence are authorized in disputes that may arise from this contract.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Force</h3>
          <p>
            This agreement enters into force upon approval by the Buyer electronically.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">Return Policy</h2>
          <p>The refund policy is applied for the services provided by Omelive.</p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Return Conditions</h3>
          <p>
            If the performance of the purchased service has not started, the Buyer may request a refund of the price of the service. There is no right of refund for video chat platform usage rights for services that are performed instantly and offered in the digital environment.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Return Request Process</h3>
          <p>
            The Buyer must submit the return request in writing to the Seller at info@omelive.online. Refund requests are evaluated within 7 business days from the date of application, and if deemed appropriate, the payment will be refunded within 14 business days, using the same method as the Buyer made the payment.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Exceptions</h3>
          <p>
            Services for which the right of withdrawal cannot be exercised: Instant services and digital contents.
          </p>

          <h3 className="text-xl font-semibold mt-4 mb-2">Contact Information</h3>
          <p>
            Trade Name: Omelive<br />
            Address: Turkey/Konya<br />
            Email: info@omelive.online
          </p>
        </div>
      </div>
      <footer className="flex flex-col items-center py-4 bg-gray-800 text-white text-sm">
        <span>© 2024 Omelive. All rights reserved.</span>
        <span>
          Contact: <a href="mailto:info@omelive.online" className="underline">info@omelive.online</a>
        </span>
      </footer>
    </div>
  );
};

export default Contract;
